<template>
  <div class="smartDTContainer">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>

      <div class="banner">
        <h4 style="font-size: 46px; font-weight: 600; padding: 0px 0 40px">
          考核与评估系统
        </h4>
        <button @click="$router.push('/freeTrial')">立即预约演示</button>
      </div>

      <div class="mainFunction">
        <IntroduceCare
          :fontDesc="mainFunction"
          class="mainFunctionCard"
        ></IntroduceCare>
      </div>

      <div class="systemArchitecture">
        <h4 class="descH4">系统功能图</h4>
        <span class="line"></span>
        <img src="@/assets/product/ProductKhypg/gnt.png" alt="" style="width:1000px;margin-top:80px">
      </div>

      <div class="systemValue">
        <h4 class="descH4">系统价值优势</h4>
        <span class="line"></span>
        <div class="valueContent">
          <div class="valueItem" v-for="(item,index) in valueList" :key="index">
            <div class="valueItemMask"></div>
            <div class="valueTitle">
              {{item.title}}
            </div>
            <div class="valueLine"></div>
            <div class="valueText">
              {{item.content}}
            </div>
          </div>
        </div>
      </div>

      <Foot></Foot>
    </div>

    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
export default {
  name: 'ProductKhypg',
  data () {
    return {
      valueList: [
        { title: '绩效指标，灵活搭建', content: '内置多种考核场景的指标模板，支持定性与定量考核、主观测评与客观测评，支持自评、互评、匿名或实名二维码等多种测评方式。' },
        { title: '按考核场景，启动项目', content: '按考核场景分类启动项目，流程化设置考核参数，个人填报考核表，各单位评定考核结果，人事处汇总考核结果。' },
        { title: '以评促改，提升数据质量', content: '自动同步业绩数据并计算考核分数、排名，支持回数据源头修改并一键获取数据，促进用户业绩数据质量的提升。' },
        { title: '结果导向，促进薪酬分配', content: '以绩效结果为导向，促进薪酬机制和分配制度的完善，为绩效奖金发放提供依据。' }
      ],
      mainFunction: [
        {
          title: '考核与评估系统',
          fontLeft: false,
          p: [
            {
              desc: '考核与测评系统是针对职工分类评价业务的管理。支持定性考核与定量考核等多种考核模式，通过对团体或个人的绩效进行全过程、全方位管理，有效提升单位的组织管理能力，充分调动员工工作积极性，为绩效工资核定发放提供标准。'
            }
          ],
          src: require('@/assets/product/ProductKhypg/khpg.png')
        }
      ]
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moProduct/moProductKhypg')
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped lang='scss'>
.smartDTContainer{
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
li {
  list-style: none;
}
.line {
  display: inline-block;
  width: 40px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.descH4 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  padding: 60px 0 20px;
}
.channelShip {
  padding-top: 80px;
}
.banner {
  width: 100%;
  height: 460px;
  background: url("~@/assets/product/ProductKhypg/banner.png") center no-repeat;
    background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.banner button {
  width: 200px;
  height: 60px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 16px;
  color: #fff;
  border: 0;
}
.banner button:hover {
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  cursor: pointer;
}

.mainFunction{
width: 100%;
height: 450px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .mainFunctionCard .CareContent{
    padding: 60px 0 40px;
}
::v-deep .mainFunctionCard .CareContent .right img{
width: 560px;
}

.systemArchitecture{
    width: 100%;
height: 588px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}

.systemValue{
     width: 100%;
height: 630px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}

.valueContent{
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
}
.valueItem{
  position: relative;
  top: 0;
  left: 0;
  width: 580px;
  margin: 0px 20px 40px;
  height: 180px;
  border-radius: 10px;
  padding: 30px 40px;
  background-color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  .valueTitle{
    font-weight: 500;
    font-size: 24px;
    color: #222222;
  }
  .valueLine{
    width: 30px;
    height: 4px;
    margin: 14px 0;
    background: #D70C0C;
  }
  .valueText{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #444;
  }
}
.valueItemMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.valueItem:nth-child(2n){
  margin-right: 0px;
}
.valueItem:nth-child(2n-1){
  margin-left: 0px;
}
.valueItem:hover{
  box-shadow: 0px 8px 20px 0px rgba(173,0,0,0.3);
    background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  .valueItemMask{
    display: block;
    background: url('~@/assets/hoverBg.png') center no-repeat;
    background-position: top left;
  }
  .valueTitle{
    color: #fff;
  }
  .valueLine{
    background-color: #fff;
  }
  .valueText{
    color: #fff;
  }
}
</style>
